/**
 * External Dependencies
 */
import 'jquery';

$(document).ready(() => {
  $('.burger-menu').click(function() {
    $(this)
      .find('.nav-drop-mobile')
      .toggleClass('show');
    console.log('click');
  });
});
